import { gql, useMutation } from "@apollo/client";
import { Flex, toast } from "@heart/components";
import PropTypes from "prop-types";
import { Fragment, useCallback, useState } from "react";

import BintiPropTypes from "@lib/BintiPropTypes";

import VoiceChat from "./common/VoiceChat";
import { MessagePropType } from "./common/common";

const SUBMIT_MESSAGE = gql`
  mutation SubmitMessage(
    $applicationFormId: ID!
    $inputText: String
    $inputAudio: Upload
    $implementation: String
  ) {
    applicationFormChat(
      input: {
        applicationFormId: $applicationFormId
        inputText: $inputText
        inputAudio: $inputAudio
        implementation: $implementation
      }
    ) {
      response
      responseAudio
      inputText
      timings
      redirectLink
    }
  }
`;

const CLEAR = gql`
  mutation ClearApplicationFormChat(
    $applicationFormId: ID!
    $implementation: String!
  ) {
    clearApplicationFormChat(
      input: {
        applicationFormId: $applicationFormId
        implementation: $implementation
      }
    ) {
      response
    }
  }
`;

/**
 * VoiceNavigation chat for filling out application forms.
 */
const ApplicationFormChat = ({
  applicationFormId,
  initialMessages = [],
  implementation = "anthropic",
  debugMode = false,
}) => {
  const [submitMessage, { loading }] = useMutation(SUBMIT_MESSAGE);
  const [clearFormChat] = useMutation(CLEAR);
  const [timings, setTimings] = useState();

  const onSubmit = async ({ input, inputAudio }) => {
    // submit the message
    const {
      data: {
        applicationFormChat: {
          response,
          responseAudio,
          inputText,
          timings: newTimings,
          redirectLink,
        },
      },
    } = await submitMessage({
      variables: {
        applicationFormId,
        input,
        inputAudio,
        implementation,
      },
    });

    setTimings(newTimings);

    if (redirectLink) {
      window.location = redirectLink;
    }

    return { response, responseAudio, inputText };
  };

  const onClear = useCallback(async () => {
    if (debugMode) {
      await clearFormChat({ variables: { applicationFormId, implementation } });
      toast.success({ title: "Chat cleared!", autoClose: 500 });
    }
  }, [clearFormChat, applicationFormId, implementation, debugMode]);

  return (
    <Fragment>
      <VoiceChat
        onClear={onClear}
        onSubmit={onSubmit}
        loading={loading}
        initialMessages={initialMessages}
        debugMode={debugMode}
      />
      <If condition={debugMode}>
        {timings && (
          <Flex column>
            <h2>Timings</h2>
            <pre>{JSON.stringify(timings, null, 2)}</pre>
          </Flex>
        )}
      </If>
    </Fragment>
  );
};

ApplicationFormChat.propTypes = {
  /**
   * Initial messages to display in the chat that may be left
   * over from a previous session.
   */
  initialMessages: PropTypes.arrayOf(MessagePropType),
  /**
   * The ID of the application form to chat about.
   */
  applicationFormId: BintiPropTypes.ID.isRequired,
  /**
   * The implementation to use for this chat.
   */
  implementation: PropTypes.string,
  /**
   * Whether to show debug information and controls.
   */
  debugMode: PropTypes.bool,
};

export default ApplicationFormChat;
