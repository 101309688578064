import { VolumeUp } from "@heart/components/icon/Icon";
import classNames from "classnames";
import EasySpeech from "easy-speech";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import { useCallback, useEffect, useMemo, useState } from "react";

import preventDefault from "@lib/preventDefault";

import styles from "./VoiceChat.module.scss";
import { ASSISTANT, USER } from "./common";

/**
 * A chat message as displayed in the chat history.
 */
const ChatMessage = ({
  role,
  message,
  inputAudio,
  timestamp,
  startPlaying = false,
  browserTTSEnabled = false,
  debugMode = false,
}) => {
  const [audioPlaying, setAudioPlaying] = useState(false);
  const inputAudioUrl = useMemo(() => {
    if (inputAudio instanceof File) {
      return URL.createObjectURL(inputAudio);
    }

    return undefined;
  }, [inputAudio]);
  const today = DateTime.now().startOf("day");
  const dateTime = DateTime.fromISO(timestamp);

  const displayTimestamp =
    dateTime.startOf("day") === today
      ? dateTime.toLocaleTimeString()
      : dateTime.toLocaleString(DateTime.DATETIME_SHORT);

  const playAudio = useCallback(async () => {
    setAudioPlaying(true);

    if (browserTTSEnabled) {
      // init is idempoent and not guaranteed to have finished before
      // speak is called even though we call it from the parent, so
      // we need to await it
      await EasySpeech.init();
      const voice = EasySpeech.voices().find(v => v.lang === "en-US");

      await EasySpeech.speak({
        text: message,
        voice,
        end: () => {
          setAudioPlaying(false);
        },
        error: () => {
          setAudioPlaying(false);
        },
      });
    } else {
      // potentially play audio from the server in the future if we find
      // we need it
    }
  }, [message, browserTTSEnabled]);

  const onClick = preventDefault(() => {
    if (audioPlaying) {
      setAudioPlaying(false);
      // speechSynthesis.cancel();
      EasySpeech.cancel();
    } else {
      playAudio();
    }
  });

  useEffect(() => {
    if (startPlaying) {
      playAudio();
    }
  }, [startPlaying, playAudio]);

  return (
    <div className={styles[role]}>
      <div className={styles.chatMessage}>
        {message}
        <If condition={role === ASSISTANT}>
          <button
            onClick={onClick}
            className={classNames(styles.assistantVoiceButton, {
              [styles.playing]: audioPlaying,
            })}
          >
            <VolumeUp />
          </button>
        </If>
        <If condition={role === USER && inputAudioUrl && debugMode}>
          {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
          <audio controls src={inputAudioUrl} />
        </If>
      </div>
      <div className={styles.chatTimestamp}>{displayTimestamp}</div>
    </div>
  );
};

ChatMessage.propTypes = {
  role: PropTypes.oneOf([USER, ASSISTANT]).isRequired,
  message: PropTypes.string.isRequired,
  timestamp: PropTypes.string.isRequired,
  startPlaying: PropTypes.bool,
  browserTTSEnabled: PropTypes.bool,
  inputAudio: PropTypes.object,
  debugMode: PropTypes.bool,
};

export default ChatMessage;
