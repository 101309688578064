import LogoSpinner from "@heart/components/loading_overlay/LogoSpinner";
import PropTypes from "prop-types";
import { useEffect, useRef } from "react";

import ChatMessage from "./ChatMessage";
import styles from "./VoiceChat.module.scss";
import { ASSISTANT, MessagePropType } from "./common";

/**
 * Chat history component that displays a list of messages in a chat.
 *
 * It may auto-play the last message if it's from the assistant and the user
 * has interacted with the page as a welcome message or resumption of the chat.
 */
const ChatHistory = ({
  messages = [],
  loading,
  hasInteracted,
  browserTTSEnabled,
  debugMode,
}) => {
  const historyRef = useRef();

  const scrollToBottom = () => {
    window.setTimeout(() => {
      if (historyRef.current) {
        historyRef.current.scrollTop = historyRef.current.scrollHeight;
      }
    }, 0);
  };

  useEffect(scrollToBottom, [messages.length, loading]);

  return (
    <div ref={historyRef} className={styles.chatContainer}>
      {messages.map(({ role, message, timestamp, inputAudio }, index) => (
        <ChatMessage
          key={index}
          role={role}
          message={message}
          timestamp={timestamp}
          startPlaying={
            index === messages.length - 1 && role === ASSISTANT && hasInteracted
          }
          browserTTSEnabled={browserTTSEnabled}
          inputAudio={inputAudio}
          debugMode={debugMode}
        />
      ))}
      <If condition={loading}>
        <div className={styles.loadingContainer}>
          <LogoSpinner />
        </div>
      </If>
    </div>
  );
};

ChatHistory.propTypes = {
  /**
   * The messages to display in the chat, if any.
   */
  messages: PropTypes.arrayOf(MessagePropType.isRequired),
  /**
   * Whether the chat is currently loading after a call to onSubmit.
   */
  loading: PropTypes.bool,
  /**
   * If the browser text-to-speech is enabled.
   */
  browserTTSEnabled: PropTypes.bool,
  /**
   * True if the user has interacted with the page.
   * (used for auto-playing welcome audio)
   */
  hasInteracted: PropTypes.bool,
  /**
   * Whether to show debug information and controls.
   */
  debugMode: PropTypes.bool,
};

export default ChatHistory;
