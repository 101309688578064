import { useMutation } from "@apollo/client";
import { Upload, Surface, PageContainer } from "@heart/components";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import React, { useState } from "react";

import { translationWithRoot } from "@components/T";

import CreateChildPhoto from "@graphql/mutations/CreateChildPhoto.graphql";
import DeleteChildPhoto from "@graphql/mutations/DeleteChildPhoto.graphql";

import Photo from "./Photo";

const { t } = translationWithRoot("photos");

/*
 * PhotoUploader component is responsible for uploading and displaying photos
 * for a child.
 */
const PhotoUploader = ({
  photos: ps,
  childId,
  canUploadChildPhoto,
  canDeleteChildPhoto,
}) => {
  const [photos, setPhotos] = useState(ps);
  const [createChildPhoto] = useMutation(CreateChildPhoto);
  const [deleteChildPhoto] = useMutation(DeleteChildPhoto);

  if (!canUploadChildPhoto) return null;

  const onUpload = async ([file]) => {
    const fileVariables = {
      file,
      category: "childPhoto",
    };

    const {
      data: {
        createChildPhoto: { photo },
      },
    } = await createChildPhoto({
      variables: {
        childId,
        ...fileVariables,
      },
    });

    setPhotos([...photos, photo]);
  };

  const onDelete = async photoId => {
    setPhotos(photos.filter(photo => photo.id !== photoId));

    const fileVariables = {
      childId,
      photoId,
    };

    await deleteChildPhoto({
      variables: {
        childId,
        ...fileVariables,
      },
    });
  };

  return (
    <PageContainer>
      <Surface title={t("upload_photos_section")} data-testid="photo-uploader">
        <Upload photosOnly onUpload={onUpload} multiple={false} />
      </Surface>
      <Surface title={t("child_photos_section")}>
        {photos.map(photo => (
          <Photo
            photo={photo}
            key={photo.id}
            onDelete={onDelete}
            isDeleteDisabled={!canDeleteChildPhoto}
          />
        ))}
        <If condition={isEmpty(photos)}>{t("no_photos_to_display")}</If>
      </Surface>
    </PageContainer>
  );
};

PhotoUploader.propTypes = {
  photos: PropTypes.array.isRequired,
  childId: PropTypes.number.isRequired,
  canUploadChildPhoto: PropTypes.bool.isRequired,
  canDeleteChildPhoto: PropTypes.bool.isRequired,
};

export default PhotoUploader;
