import { gql, useMutation } from "@apollo/client";
import { Flex, toast } from "@heart/components";
import PropTypes from "prop-types";
import { Fragment, useCallback, useState } from "react";

import VoiceChat from "./common/VoiceChat";
import { MessagePropType } from "./common/common";

const SUBMIT_MESSAGE = gql`
  mutation SubmitMessage(
    $inputText: String
    $inputAudio: Upload
    $implementation: String
  ) {
    licensingMainMenuChat(
      input: {
        inputText: $inputText
        inputAudio: $inputAudio
        implementation: $implementation
      }
    ) {
      response
      responseAudio
      inputText
      timings
      redirectLink
    }
  }
`;

const CLEAR = gql`
  mutation ClearLicensingMainMenuChat($implementation: String!) {
    clearLicensingMainMenuChat(input: { implementation: $implementation }) {
      response
    }
  }
`;

/**
 * VoiceNavigation chat for the Licensing Main Menu.
 */
const LicensingMainMenuChat = ({
  initialMessages = [],
  implementation = "anthropic",
  debugMode = false,
}) => {
  const [submitMessage, { loading }] = useMutation(SUBMIT_MESSAGE);
  const [clear] = useMutation(CLEAR);
  const [timings, setTimings] = useState();
  const [onPlaybackEnd, setOnPlaybackEnd] = useState(() => () => {});

  const onSubmit = useCallback(
    async ({ input, inputAudio }) => {
      // submit the message
      const {
        data: {
          licensingMainMenuChat: {
            response,
            responseAudio,
            inputText,
            timings: newTimings,
            redirectLink,
          },
        },
      } = await submitMessage({
        variables: {
          inputText: input,
          inputAudio,
          implementation,
        },
      });

      setTimings(newTimings);

      if (redirectLink) {
        setOnPlaybackEnd(() => {
          window.location = redirectLink;
        });
      }

      return { response, responseAudio, inputText };
    },
    [implementation, submitMessage]
  );

  const onClear = useCallback(async () => {
    if (debugMode) {
      await clear({ variables: { implementation } });
      toast.success({ title: "Chat cleared!", autoClose: 500 });
    }
  }, [clear, implementation, debugMode]);

  return (
    <Fragment>
      <VoiceChat
        onClear={onClear}
        onSubmit={onSubmit}
        loading={loading}
        onPlaybackEnd={onPlaybackEnd}
        initialMessages={initialMessages}
        debugMode={debugMode}
      />
      {timings && (
        <Flex column>
          <h2>Timings</h2>
          <pre>{JSON.stringify(timings, null, 2)}</pre>
        </Flex>
      )}
    </Fragment>
  );
};

LicensingMainMenuChat.propTypes = {
  /**
   * Initial messages to display in the chat that may be left
   * over from a previous session.
   */
  initialMessages: PropTypes.arrayOf(MessagePropType),
  /**
   * The implementation to use for this chat.
   */
  implementation: PropTypes.string,
  /**
   * Whether to show debug information and controls.
   */
  debugMode: PropTypes.bool,
};

export default LicensingMainMenuChat;
